export default class MyCustomSetup {

    constructor() { }

    id = null;

    indexName = null;

    whenNegative = false;

    whenPositive = false;

    onlyUnderThan = null;

    onlyAboveThan = null;

    useMiddleBand = null;

    useBottomBand = null;

    useTopBand = null;

    priceUpToPercent = null;

    priceDownToPercent = null;

    stopGainPercentage = null;

    stopLossPercentage = null;


}