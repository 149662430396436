<template>

  <div>
    <v-expand-transition>
      <v-banner v-if="showTip"
        color="blue-darken-1"
        icon="mdi-tooltip"
        lines="five"
      >
        <template v-slot:prepend>
          <v-avatar></v-avatar>
        </template>

        <v-banner-text class="text-body-1 text-justify">
          Aqui você pode criar setups de teste. Os setups de teste ficam ativos e sempre que uma condição de compra ou venda 
          acontecer, o Tourmalet vai criar uma transação fake com aquele setup. <br /><br />
          Experimente criar setups e acompanhar os resultados 
          que eles te trazem. Após algumas transações feitas com o setup de teste, você pode promovê-lo para um setup de verdade!
        </v-banner-text>

        <v-banner-actions>
          <v-btn color="primary" @click="showTip = false">Fechar Dica</v-btn>
        </v-banner-actions>
      </v-banner>
    </v-expand-transition>
    
    <v-card :disabled="store.dialogLoader" class="elevation-10">
      <v-toolbar color="primary">
        <v-toolbar-title>Setups de Teste</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="">
        <div>
          <v-btn variant="flat" color="grey-darken-3" width="100%" append-icon="mdi-plus" @click="operNewSetupDialog">
            Criar novo
          </v-btn>

        </div>

      </v-card-text>

    </v-card>

    <v-row justify="center">
      <v-dialog
        v-model="dialogAddSetup"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>Criar Setup</v-toolbar-title>
            <v-toolbar-items>
              <v-btn
                @click="dialogAddSetup = false"
              >
              <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          
          <v-card-text class="">
            
            <v-card title="Condição de Compra" class="elevation-10" v-if="setupBuy && marketIndexList">
              <v-card-text class="pt-2">
                
                <v-row>
                  <v-col cols="12" md="3" sm="12" class="pb-0">
                    <v-select :items="marketIndexList" label="Índice de Mercado"
                      v-model="setupBuy.indexName" @change="resetAllFromBuy">
                    </v-select>
                  </v-col>

                  <!-- bollinger -->
                  <v-expand-transition>
                    <v-col cols="12" md="4" sm="12" class="pb-0"
                      v-if="setupBuy.indexName && setupBuy.indexName.toLowerCase().includes('bollinger')">
                      <v-select :items="conditionBollingerBuy" label="Condição" item-title="label" return-object
                        v-model="conditionBollingerBuyChoice" :key="conditionBollingerBuyChoice"
                        @update:modelValue="selectBollingerOption">
                      </v-select>
                    </v-col>
                  </v-expand-transition>
                  <v-col cols="12" md="4" sm="12" class="pb-0 d-none d-sm-flex" v-if="!setupBuy.indexName">
                    <v-text-field disabled variant="outlined" label="Valor 1">
                    </v-text-field>
                  </v-col>
                  
                  <v-expand-transition>
                    <v-col cols="12" md="4" sm="12" class="pb-0"
                      v-if="conditionBollingerBuyChoice">
                      <div class="text-caption">% abaixo da banda</div>
                      <v-slider
                        v-model="setupBuy.priceDownToPercent"
                        :min="-0.1"
                        :max="-20"
                        :step="-0.2"
                        show-ticks="always"
                        thumb-label
                      ></v-slider>
                    </v-col>
                  </v-expand-transition>
                  

                  <!-- RSI -->
                  <v-expand-transition>
                    <v-col cols="12" md="4" sm="12" class="pb-0" 
                      v-if="setupBuy.indexName && setupBuy.indexName.toLowerCase().includes('rsi')">
                      <v-select :items="conditionRSIBuy" label="Condição" item-title="label" return-object
                        v-model="conditionRSIBuyChoice" :key="conditionRSIBuyChoice"
                        @update:modelValue="selectRSIOption">
                      </v-select>
                    </v-col>
                  </v-expand-transition>
                  
                  <v-expand-transition>
                    <v-col cols="12" md="4" sm="12" class="pb-0"
                      v-if="conditionRSIBuyChoice && (rsiBiggerThan || rsiSmallerThan)">
                      <div class="text-caption">Valor do RSI</div>
                      <v-slider v-if="rsiBiggerThan"
                        v-model="setupBuy.onlyAboveThan"
                        :min="10"
                        :max="90"
                        :step="1"
                        show-ticks="always"
                        thumb-label
                      ></v-slider>
                      <v-slider v-if="rsiSmallerThan"
                        v-model="setupBuy.onlyUnderThan"
                        :min="10"
                        :max="90"
                        :step="1"
                        show-ticks="always"
                        thumb-label
                      ></v-slider>
                    </v-col>
                  </v-expand-transition>
                  

                  
                  <v-col cols="12" md="4" sm="12" class="pb-0 d-none d-sm-flex" v-if="!conditionBollingerBuyChoice && !conditionRSIBuyChoice">
                    <v-text-field disabled variant="outlined" label="Valor 2">
                    </v-text-field>
                  </v-col>
                  
                  <!-- :disabled="(!priceDownToPercent && !priceUpToPercent)
                        && (!onlyAboveThan && !onlyUnderThan)" -->
                  <v-col cols="12" md="1" sm="12" class="pb-0">
                    <v-btn icon variant="flat" color="primary" @click="addBuyItem"
                      :disabled="(!setupBuy.priceDownToPercent && !setupBuy.priceUpToPercent)
                        && (!setupBuy.onlyAboveThan && !setupBuy.onlyUnderThan)"
                      class="d-none d-sm-flex">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn variant="flat" color="primary" width="100%" @click="addBuyItem"
                      class="d-sm-none d-flex mb-4">
                      Adicionar
                    </v-btn>
                  </v-col>

                  <v-col cols="12" v-if="indexBuyList.length > 0">
                    <v-spacer></v-spacer>
                  </v-col>
                  <v-col cols="12" v-if="indexBuyList.length > 0">
                    <div class="font-weight-black mb-3 text-h5">Comprar:</div>
                    <div v-for="item in indexBuyList" :key="item.indexName" class="mb-2">

                      <!-- bollinger -->
                      <span v-if="item.indexName.toLowerCase().includes('bollinger')" class="text-body-1">
                        <v-icon class="mr-2" color="primary">mdi-arrow-right</v-icon>
                        <span>{{ item.indexName }} : </span>
                        <span v-if="item.useBottomBand">
                          Preço {{ truncateToScale(item.priceDownToPercent, 2) }}% abaixo da banda menor
                        </span>
                        <span v-if="item.useMiddleBand">
                          Preço {{ truncateToScale(item.priceDownToPercent, 2) }}% abaixo da banda do meio
                        </span>
                      </span>

                      <!-- RSI -->
                      <span v-if="item.indexName.toLowerCase().includes('rsi')" class="text-body-1">
                        <v-icon class="mr-2" color="primary">mdi-arrow-right</v-icon>
                        <span>{{ item.indexName }} : </span>
                        <span v-if="item.onlyUnderThan">
                          Abaixo de {{ item.onlyUnderThan }}
                        </span>
                        <span v-if="item.onlyAboveThan">
                          Acima de {{ item.onlyAboveThan }}
                        </span>
                      </span>

                    </div>
                  </v-col>
                </v-row>

              </v-card-text>

            </v-card>

            <v-card title="Condição de Venda" class="elevation-10 mt-4">
              
            </v-card>

            <v-card title="Condição de Venda" class="elevation-10 mt-4">
              {{ setupBuy }}
              <br />
              {{ conditionBollingerBuyChoice }}
              <br />
              conditionBollingerBuyChoice {{ conditionBollingerBuyChoice }}
              <br />
              conditionRSIBuyChoice {{ conditionRSIBuyChoice }}
            </v-card>

          </v-card-text>

        </v-card>
      </v-dialog>
    </v-row>

    


  </div>


</template>

<script>

import { myMixin } from '../../mixin';
import services from '../../ws/services';
import { store } from '../../store';
import MyCustomSetup from '../../model/MyCustomSetup';

//import { ref, watch } from 'vue'

export default {

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  setup() {

  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    showTip : true,

    dialogAddSetup : false,

    setupBuy : null,
    setupSell : null,

    marketIndexList : null,

    indexBuyList : [],
    indexSellList : [],

    //bollinger:
    conditionBollingerBuy : [
      { label : 'Preço abaixo da banda menor', useTopBand : false, useMiddleBand : false, useBottomBand : true}, 
      { label : 'Preço abaixo da banda do meio', useTopBand : false, useMiddleBand : true, useBottomBand : false}],
    conditionBollingerBuyChoice : null,

    //RSI:
    conditionRSIBuy : [
      { label : 'Quando for menor que', rsiSmallerThan : true, rsiBiggerThan : false }, 
      { label : 'Quando for maior que', rsiSmallerThan : false, rsiBiggerThan : true }],
    conditionRSIBuyChoice : null,
    rsiBiggerThan : false,
    rsiSmallerThan : false,

    //common:
    onlyUnderThan : null,
    onlyAboveThan : null,

    //comons fields:
    priceDownToPercent : null,

  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    //this.simpleServiceCall();
    //this.loadBasicGlobalData();
  },

  

  methods: {

    addBuyItem(){
      this.indexBuyList.push(this.setupBuy);
      this.setupBuy = new MyCustomSetup();
      this.resetAllFromBuy();
    },

    selectBollingerOption(){
      console.log('selected conditionBollingerBuyChoice');
      if(this.conditionBollingerBuyChoice){
        console.log('setting the fields');
        this.setupBuy.useMiddleBand = this.conditionBollingerBuyChoice.useMiddleBand;
        this.setupBuy.useBottomBand = this.conditionBollingerBuyChoice.useBottomBand;
        this.setupBuy.useTopBand = this.conditionBollingerBuyChoice.useTopBand;
      }
    },

    selectRSIOption(){
      console.log('selected conditionRSIBuyChoice');
      if(this.conditionRSIBuyChoice){
        console.log('setting the fields');
        this.rsiBiggerThan = this.conditionRSIBuyChoice.rsiBiggerThan;
        this.rsiSmallerThan = this.conditionRSIBuyChoice.rsiSmallerThan;
      }
    },

    resetAllFromBuy(){
      
      //bollinger
      this.priceDownToPercent = null;
      this.priceUpToPercent = null;
      this.conditionBollingerBuyChoice = null;
      
      //RSI
      this.setupBuy.onlyAboveThan = null;
      this.setupBuy.onlyUnderThan = null;
      this.rsiBiggerThan = false;
      this.rsiSmallerThan = false;
      this.conditionRSIBuyChoice = null;
      
      //common:
      this.onlyUnderThan = null;
      this.onlyAboveThan = null;

    },

    operNewSetupDialog(){

      console.log('opening new setup...');
      this.dialogAddSetup = true;

      this.setupBuy = new MyCustomSetup();
      this.setupSell = new MyCustomSetup();

      this.loadMarketIndexes();
    },

    loadMarketIndexes(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
      .post(services.serviceHost + "/custom-setup/allmarketindexes", {}, config)
      .then(response => {
        //console.log(response.data);
        this.marketIndexList = response.data;
      });
    }

  }

}

</script>

<style scoped>


</style>
