<template>
  
  <v-card class="mb-4 elevation-10 pa-6 text-h5" :disabled="store.dialogLoader">
    <v-icon class="mr-2" color="primary">mdi-robot-excited-outline</v-icon>
    Olá {{ store.userLogged.firstName }} 
  </v-card>

  
  <v-expand-transition>
    <v-card class="mb-4 elevation-10" v-if="robotConfig && !robotConfig.robotOperating">
      <v-card-text class="text-error text-center text-body-1">
        No momento, devido a instabilidades do mercado e sinais que apontam para fortes baixas, 
        <span class="font-weight-black">o robô está em estado espera até 
        {{ robotConfig.stopTransactionsUntil }}hs.</span> Até lá o robô não fará nenhuma operação de compra. 
        <br /><br />
        Neste tempo, algumas de suas posições podem ser liquidadas com valores negativos, para evitar perdas ainda maiores. 
      </v-card-text>
    </v-card>
  </v-expand-transition>

  <v-expand-transition>
    <v-card class="mb-4 elevation-10" v-if="totalBNB">
      <v-card-text class="">
        <div class="text-body-1">Seu total em BNB: {{ formataMoedaUSD(totalBNB) }} USDT</div>
        <div class="text-body-2 text-error font-italic mt-3 text-justify" v-if="totalBNB < 5">
          Sempre recomendamos manter seu total em BNB (a moeda da Binance) acima de 10.00 USDT. A Binance cobra taxa nas transações, e 
          o valor das taxas fica signifativamente mais baixo se você tiver BNB na sua conta - porque as taxas serão automaticamente 
          debitadas do seu BNB. 
        </div>
      </v-card-text>
    </v-card>
  </v-expand-transition>

  <v-expand-transition>
    <v-card class="mb-4 elevation-10" v-if="activeCoinList">
      <v-toolbar color="primary">
        <v-toolbar-title>Moedas Ativas no Robô</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="">
        <v-chip v-for="coin in activeCoinList" :key="coin.id"
          class="ma-2 elevation-2"
          color="grey-darken-4"
        >
          {{ coin.symbol }}
        </v-chip>
      </v-card-text>
    </v-card>
  </v-expand-transition>

  <v-row class="">

    <v-expand-transition>
      <v-col cols="12" md="6" sm="12" v-if="(totalTransactions7 && totalTransactions7 > 0) || (totalTransactions30 && totalTransactions30 > 0)" :disabled="store.dialogLoader">  
        <v-card class="elevation-10 mb-4" v-if="totalTransactions7 && totalTransactions7 > 0">
          <v-toolbar color="primary">
            <v-toolbar-title>Últimos 7 dias</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div>
              <v-row>
                <v-col cols="6"  class="">
                  Total de Transações
                </v-col>
                <v-col cols="6"  class="">
                  {{ totalTransactions7 }}
                </v-col>

                <v-col cols="6"  class="">
                  Total Ganho
                </v-col>
                <v-col cols="6"  class="text-success">
                  $ {{ formataMoedaUSD(totalPositiveResult7) }} USDT
                </v-col>

                <v-col cols="6"  class="">
                  Total Perdido
                </v-col>
                <v-col cols="6"  class="text-error" v-if="totalNegativeResult7">
                  $ {{ formataMoedaUSD(totalNegativeResult7) }} USDT
                </v-col>
                <v-col cols="6"  class="text-error" v-if="!totalNegativeResult7 || totalNegativeResult7 == 0">
                  Sem perdas no período
                </v-col>

                <v-col cols="6"  class="">
                  Resultado
                </v-col>
                <v-col cols="6"  :class="(totalPositiveResult7 + totalNegativeResult7) > 0 ? 'text-success' : 'text-error'" 
                  v-if="totalNegativeResult7 || totalPositiveResult7">
                  $ {{ formataMoedaUSD(totalPositiveResult7 + totalNegativeResult7) }} USDT
                  <v-icon v-if="(totalPositiveResult7 + totalNegativeResult7) > 0" size="small" class="mb-1">mdi-arrow-up</v-icon>
                  <v-icon v-if="(totalPositiveResult7 + totalNegativeResult7) < 0" size="small" class="mb-1">mdi-arrow-down</v-icon>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="elevation-10 mb-4" v-if="totalTransactions30 && totalTransactions30 > 0">
          <v-toolbar color="primary">
            <v-toolbar-title>Últimos 30 dias</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div>
              <v-row>
                <v-col cols="6"  class="">
                  Total de Transações
                </v-col>
                <v-col cols="6"  class="">
                  {{ totalTransactions30 }}
                </v-col>

                <v-col cols="6"  class="">
                  Total Ganho
                </v-col>
                <v-col cols="6"  class="text-success">
                  $ {{ formataMoedaUSD(totalPositiveResult30) }} USDT
                </v-col>

                <v-col cols="6"  class="">
                  Total Perdido
                </v-col>
                <v-col cols="6"  class="text-error" v-if="totalNegativeResult30">
                  $ {{ formataMoedaUSD(totalNegativeResult30) }} USDT
                </v-col>
                <v-col cols="6"  class="text-error" v-if="!totalNegativeResult30 || totalNegativeResult30 == 0">
                  Sem perdas no período
                </v-col>

                <v-col cols="6"  class="">
                  Resultado
                </v-col>
                <v-col cols="6"  :class="(totalPositiveResult30 + totalNegativeResult30) > 0 ? 'text-success' : 'text-error'" 
                  v-if="totalNegativeResult30 || totalPositiveResult30">
                  $ {{ formataMoedaUSD(totalPositiveResult30 + totalNegativeResult30) }} USDT
                  <v-icon v-if="(totalPositiveResult30 + totalNegativeResult30) > 0" size="small" class="mb-1">mdi-arrow-up</v-icon>
                  <v-icon v-if="(totalPositiveResult30 + totalNegativeResult30) < 0" size="small" class="mb-1">mdi-arrow-down</v-icon>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="elevation-10 d-md-none d-sm-flex" v-if="totalTransactions180 && totalTransactions180 > 30">
          <v-toolbar color="primary">
            <v-toolbar-title>Últimos 180 dias</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div>
              <v-row>
                <v-col cols="6"  class="">
                  Total de Transações
                </v-col>
                <v-col cols="6"  class="">
                  {{ totalTransactions180 }}
                </v-col>

                <v-col cols="6"  class="">
                  Total Ganho
                </v-col>
                <v-col cols="6"  class="text-success">
                  $ {{ formataMoedaUSD(totalPositiveResult180) }} USDT
                </v-col>

                <v-col cols="6"  class="">
                  Total Perdido
                </v-col>
                <v-col cols="6"  class="text-error" v-if="totalNegativeResult180">
                  $ {{ formataMoedaUSD(totalNegativeResult180) }} USDT
                </v-col>
                <v-col cols="6"  class="text-error" v-if="!totalNegativeResult180 || totalNegativeResult180 == 0">
                  Sem perdas no período
                </v-col>

                <v-col cols="6"  class="">
                  Resultado
                </v-col>
                <v-col cols="6"  :class="(totalPositiveResult180 + totalNegativeResult180) > 0 ? 'text-success' : 'text-error'" 
                  v-if="totalNegativeResult180 || totalPositiveResult180">
                  $ {{ formataMoedaUSD(totalPositiveResult180 + totalNegativeResult180) }} USDT
                  <v-icon v-if="(totalPositiveResult180 + totalNegativeResult180) > 0" size="small" class="mb-1">mdi-arrow-up</v-icon>
                  <v-icon v-if="(totalPositiveResult180 + totalNegativeResult180) < 0" size="small" class="mb-1">mdi-arrow-down</v-icon>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-expand-transition>
    
    <v-expand-transition>
      <v-col cols="12" md="6" sm="12" v-if="!store.isCurrentAPIvalid">
        <v-card class="mb-4 elevation-10" :disabled="store.dialogLoader" >
          <v-toolbar color="primary">
            <v-toolbar-title>Status</v-toolbar-title>
              <v-icon class="mr-6">mdi-robot-confused-outline</v-icon>
          </v-toolbar>
          <v-card-text class="">
            <div v-if="store.userLogged && (store.userLogged.myConfiguration && store.userLogged.myConfiguration.enabled)" class="mb-2">
              <v-icon color="green" class="mr-4">mdi-thumb-up</v-icon>Seu robô está ligado.
            </div>
            <div v-if="store.userLogged && (!store.userLogged.myConfiguration || !store.userLogged.myConfiguration.enabled)" class="mb-2">
              <v-icon color="red" class="mr-4">mdi-pause-circle</v-icon>Seu robô está desligado
            </div>
            <div v-if="store.isCurrentAPIvalid" class="mb-2">
              <v-icon color="green" class="mr-4">mdi-thumb-up</v-icon>Sua API está válida.
            </div>
            <div v-if="!store.isCurrentAPIvalid" class="mb-2">
              <v-icon color="red" class="mr-4">mdi-thumb-down</v-icon>Sua API não está válida.
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn width="49%" color="black" dark link to="/apiManager" variant="outlined">
              Ajustar API
              <v-icon color="green-lighten-1" class="ml-4">
                mdi-arrow-right
              </v-icon>
            </v-btn>  
            <v-btn  width="49%" color="black" dark link to="/robotConfig" class="" variant="outlined">
              Config Robô
              <v-icon color="green-lighten-1" class="ml-4">
                mdi-arrow-right
              </v-icon>
            </v-btn>  
          </v-card-actions>
        </v-card>
      </v-col>
    </v-expand-transition>

    <v-col cols="12" md="6" sm="12" v-if="lastPositiveItems && lastPositiveItems.length > 0" :disabled="store.dialogLoader">
      <v-card class="elevation-10">
        <v-toolbar color="primary">
          <v-toolbar-title>Seus ganhos recentes</v-toolbar-title>
            <v-icon class="mr-6">mdi-arrow-up</v-icon>
        </v-toolbar>
        <v-card-text>
          <div>
            <v-row>
              <template v-for="(item, index) in lastPositiveItems" :key="item.id">
                <v-col cols="7"  class="">
                  {{item.symbol}} <br class="d-flex d-sm-none"/> <span style="color : #99A3A4;">(Setup: {{ item.methodOfBuy }})</span><br/> 
                  {{item.dtTransactionSell}}
                </v-col>
                <v-col cols="5"  class="text-right">
                  <span  v-if="item.transactionStatus.toLowerCase() == 'filled' && ((item.totalAmmountBuy/item.totalAmmountSell)*100) > 0" 
                    v-bind:style="formataMoedaUSD((100 - ((item.totalAmmountBuy/item.totalAmmountSell)*100))) >= 0 ? 'color : rgb(113, 179, 15);' : 'color : rgb(113, 179, 15);'">
                    {{formatDecimalToUSD( (100 - ((item.totalAmmountBuy/item.totalAmmountSell)*100)), 4)}} %
                  </span>
                  <br/>
                  Variação
                </v-col>
                <v-col cols="12" class="" v-if="index < lastPositiveItems.length - 1"
                  :key="`${index}-divider`">
                  <v-divider></v-divider>
                </v-col>
              </template>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn width="100%" color="black" dark link to="/myTransactions">
            Ver todas as transações
            <v-icon color="grey-lighten-1" class="ml-4">
              mdi-arrow-right
            </v-icon>
          </v-btn>  
        </v-card-actions>
      </v-card>
    </v-col>

    <v-expand-transition>
      <v-col cols="12" md="6" sm="12" v-if="store.isCurrentAPIvalid">
        <v-card class="elevation-10" :disabled="store.dialogLoader">
          <v-toolbar color="primary">
            <v-toolbar-title>Status</v-toolbar-title>
              <v-icon class="mr-6">mdi-robot-confused-outline</v-icon>
          </v-toolbar>
          <v-card-text class="">
            <div v-if="store.userLogged && (store.userLogged.myConfiguration && store.userLogged.myConfiguration.enabled)" class="mb-2">
              <v-icon color="green" class="mr-4">mdi-thumb-up</v-icon>Seu robô está ligado.
            </div>
            <div v-if="store.userLogged && (!store.userLogged.myConfiguration || !store.userLogged.myConfiguration.enabled)" class="mb-2">
              <v-icon color="red" class="mr-4">mdi-pause-circle</v-icon>Seu robô está desligado
            </div>
            <div v-if="store.isCurrentAPIvalid" class="mb-2">
              <v-icon color="green" class="mr-4">mdi-thumb-up</v-icon>Sua API está válida.
            </div>
            <div v-if="!store.isCurrentAPIvalid" class="mb-2">
              <v-icon color="red" class="mr-4">mdi-thumb-down</v-icon>Sua API não está válida.
            </div>
            <div v-if="store.isCurrentAPIvalid && store.userLogged.myConfiguration" class="mb-2">
              <v-icon color="green" class="mr-4">mdi-currency-usd</v-icon>
              Valor padrão das trades: {{ formataMoedaUSD(store.userLogged.myConfiguration.ammountUSDTToBuy) }} USDT
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn width="49%" color="black" dark link to="/apiManager" variant="outlined">
              Ajustar API
              <v-icon color="green-lighten-1" class="ml-4">
                mdi-arrow-right
              </v-icon>
            </v-btn>  
            <v-btn  width="49%" color="black" dark link to="/robotConfig" class="" variant="outlined">
              Config Robô
              <v-icon color="green-lighten-1" class="ml-4">
                mdi-arrow-right
              </v-icon>
            </v-btn>  
          </v-card-actions>
        </v-card>
      </v-col>
    </v-expand-transition>

    <v-expand-transition>
      <v-col cols="12" md="6" sm="12" v-if="resultSummary">
        <v-card class="elevation-10 mb-10" :disabled="store.dialogLoader">
          <v-toolbar color="primary">
            <v-toolbar-title>Números do Tourmalet</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div>
              <v-row>
                <v-col cols="6"  class="">
                  Total de Transações
                </v-col>
                <v-col cols="6"  class="">
                  {{ resultSummary.countAllClosedTransactions }}
                </v-col>

                <v-col cols="6"  class="">
                  Total Movimentado
                </v-col>
                <v-col cols="6"  class="">
                  $ {{ formataMoedaUSD(resultSummary.sumAllMoneyIOnTransactions) }} USDT
                </v-col>

                <v-col cols="6"  class="">
                  Total em Lucro
                </v-col>
                <v-col cols="6"  class="">
                  $ {{ formataMoedaUSD(resultSummary.sumAllPositiveTransactions) }} USDT
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-expand-transition>

  </v-row>
  
</template>

<script>

import { myMixin } from '../mixin';
import services from '../ws/services';
import { store } from '../store';

export default {

  mixins: [
    myMixin,
  ],

  computed : {
    
  },

  data: () => ({
      
    errorMessage : null,
    store,

    isSmallScreen : false,

    lastPositiveItems: [],

    resultSummary : null,

    purchases : null,

    totalTransactions7 : null,
    totalPositiveResult7 : null,
    totalNegativeResult7 : null,

    totalTransactions30 : null,
    totalPositiveResult30 : null,
    totalNegativeResult30 : null,

    totalTransactions180 : null,
    totalPositiveResult180 : null,
    totalNegativeResult180 : null,

    totalBNB : null,

    activeCoinList : null,

    robotConfig : null,

  }),

  beforeMount (){
    this.isSmallScreen = this.$vuetify.display.mobile;
  },  

  mounted() {
    this.reloadItemList();
    this.loadReportsummary();

    //endpoints de totais:
    this.loadTotalTransactions7();
    this.loadTotalNegativeResult7();
    this.loadTotalPositiveResult7();
    this.loadTotalTransactions30();
    this.loadTotalNegativeResult30();
    this.loadTotalPositiveResult30();
    this.loadTotalTransactions180();
    this.loadTotalNegativeResult180();
    this.loadTotalPositiveResult180();

    //listando moedas ativas:
    this.loadActiveCoinList();

    //global config:
    this.loadGlobalConfig();


    this.testLoggedUserAPI();

    setTimeout(() => {
      this.loadBNBBalance();
    }, 4000) // 3000 milliseconds = 3 seconds
  },

  methods: {

    loadGlobalConfig(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
      .post(services.serviceHost + "/global-config/load", {}, config)
      .then(response => {
        this.robotConfig = response.data;
        //console.log(JSON.stringify(response.data));
      })
      .catch(error => {
        console.log(error);
        this.softLogout();
      });
        
    },

    loadActiveCoinList(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/symbol/allactive", {}, config)
        .then(response => {
        //console.log('active coins: \n\n' + JSON.stringify(response.data));
        this.activeCoinList = response.data;
      });
      
    },

    loadBNBBalance(){

      if(store.isCurrentAPIvalid){

        const config = {
          headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
          .post(services.serviceHost + "/wallet/total-balance/BNB", {}, config)
          .then(response => {
          //console.log(JSON.stringify(response.data));
          this.totalBNB = response.data;
        });
      }
    },

    loadTotalTransactions7(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/count/7", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.totalTransactions7 = response.data;
      });
    },

    loadTotalPositiveResult7(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/positive/sum/7", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.totalPositiveResult7 = response.data;
      });
    },

    loadTotalNegativeResult7(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/negative/sum/7", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.totalNegativeResult7 = response.data;
      });
    },

    loadTotalTransactions30(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/count/30", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.totalTransactions30 = response.data;
      });
    },

    loadTotalPositiveResult30(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/positive/sum/30", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.totalPositiveResult30 = response.data;
      });
    },

    loadTotalNegativeResult30(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/negative/sum/30", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.totalNegativeResult30 = response.data;
      });
    },

    loadTotalTransactions180(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/count/183", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.totalTransactions180 = response.data;
      });
    },

    loadTotalPositiveResult180(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/positive/sum/183", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.totalPositiveResult180 = response.data;
      });
    },

    loadTotalNegativeResult180(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/transactions/negative/sum/183", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.totalNegativeResult180 = response.data;
      });
    },    

    loadReportsummary(){

      const config = {
        headers: { Authorization: `Bearer ${store.currentToken}` }
      };
      this.axios
        .post(services.serviceHost + "/robot/resultsummaryreport", {}, config)
        .then(response => {
        //console.log(JSON.stringify(response.data));
        this.resultSummary = response.data;
      });
    },

    reloadItemList() {
        store.dialogLoader = true;
        const config = {
            headers: { Authorization: `Bearer ${store.currentToken}` }
        };
        this.axios
            .post(services.serviceHost + "/robot/order/my-positive/all/5", {}, config)
            .then(response => {
            this.lastPositiveItems = response.data;
            //console.log(JSON.stringify(this.items));
            store.dialogLoader = false;
            //this.updateCoinList();
            console.log('list size: ' + this.lastPositiveItems.length);
        })
            .catch(error => {
            console.log(error.message);
            this.logout();
        });
    },

  }

    

}

</script>

<style scoped>


</style>
